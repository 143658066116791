import { useEffect, useRef } from "react";
import Container from "../../container";
import Bulb from "../../svg/bulb";
import Devices from "../../svg/devices";
import Rocket from "../../svg/Rocket";
import SpeedMeter from "../../svg/speed-meter";
import { animate, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useContextUpdater } from "../../../context";
import userImg from "../../../assets/images/profile.png";

const about = [
  {
    heading: "Fast",
    description:
      "Fast load times and lag free interaction, my highest priority.",
    icon: <SpeedMeter />,
  },
  {
    heading: "Intuitive",
    description: "Strong preference for easy to use, intuitive UX/UI.",
    icon: <Bulb />,
  },
  {
    heading: "Responsive",
    description: "My layouts will work on any device, big or small.",
    icon: <Devices />,
  },
  {
    heading: "Dynamic",
    description:
      "Websites don't have to be static, I love making pages come to life.",
    icon: <Rocket />,
  },
];

const skills = [
  {
    title: "JavaScript",
    amount: 85,
  },
  {
    title: "React",
    amount: 80,
  },
  {
    title: "Go Lang",
    amount: 50,
  },
  {
    title: "WordPress",
    amount: 70,
  },
  {
    title: "PHP",
    amount: 70,
  },
  {
    title: "Redux",
    amount: 70,
  },
  {
    title: "TypeScript",
    amount: 70,
  },
  {
    title: "NodeJS",
    amount: 70,
  },
  {
    title: "CSS",
    amount: 70,
  },
  {
    title: "HTML",
    amount: 80,
  },
  {
    title: "Figma",
    amount: 40,
  },
  {
    title: "Git/Github",
    amount: 70,
  },
];

export default function About() {
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const { setActiveNav } = useContextUpdater();

  useEffect(() => {
    if (inView) {
      setActiveNav("about");
    }
  }, [inView]);

  return (
    <Container heading="About">
      <motion.div className="grid items-baseline grid-cols-2 px-2 mt-20 gap-y-12 md:gap-x-20 lg:grid-cols-4 ">
        {about.map((item, index) => {
          const { description, heading, icon } = item;
          return (
            <div
              className="flex flex-col items-center justify-center space-y-2"
              key={heading}
            >
              <AboutItem
                index={index}
                description={description}
                heading={heading}
                icon={icon}
              />
            </div>
          );
        })}
      </motion.div>
      <div className="grid mt-20 lg:grid-cols-2" ref={ref}>
        <div className="flex flex-col items-center justify-center ">
          <motion.div
            className="flex items-center justify-center bg-black bg-center bg-cover hex h-60 w-60 md:h-64 sm:w-64"
            style={{
              // backgroundImage: `url('https://images.unsplash.com/photo-1633332755192-727a05c4013d?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880')`,
              backgroundImage: `url(${userImg})`,
            }}
            transition={{
              duration: 1,
              delay: 1,
              type: "spring",
              stiffness: 100,
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{
              once: true,
            }}
            variants={{
              hidden: {
                x: "-100%",
                opacity: 0,
              },
              visible: {
                x: 0,
                opacity: [0, 0.5, 1],
              },
            }}
          ></motion.div>
          <motion.h3
            className="mt-4 text-lg font-medium text-gray-700 md:text-xl dark:text-gray-50"
            transition={{
              duration: 1,
              delay: 1,
              type: "spring",
              stiffness: 100,
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{
              once: true,
            }}
            variants={{
              hidden: {
                x: "60vw",
                opacity: 0,
              },
              visible: {
                x: 0,
                opacity: [0, 0.5, 1],
              },
            }}
          >
            Who&apos;s me?
          </motion.h3>
          <motion.p
            className="text-[13px] md:text-sm lg:text-base text-center text-gray-500 mt-2 px-6 sm:px-20 dark:text-gray-200"
            transition={{
              duration: 1,
              delay: 2,
            }}
            initial="hidden"
            whileInView="visible"
            viewport={{
              once: true,
            }}
            variants={{
              hidden: {
                opacity: 0,
              },
              visible: {
                opacity: 1,
              },
            }}
          >
            I&apos;m a{" "}
            <span className="font-medium text-primary">Frontend Engineer</span>{" "}
            for{" "}
            <a
              href="https://canary.tools/"
              target="_blank"
              rel="noreferrer"
              className="font-medium underline text-primary "
            >
              Thinkst Applied Research
            </a>{" "}
            South Africa · Remote. I have serious passion for Programming, UI/UX
            effects, animations and creating intuitive, dynamic user
            experiences. <br />
            <a
              href="#contact"
              className="font-medium underline text-primary"
              rel="noreferrer"
            >
              Let&apos;s talk!
            </a>
          </motion.p>
        </div>
        <motion.div
          className="px-8 mt-20 space-y-4"
          animate={!inView ? "initial" : "visible"}
          initial="hidden"
          whileInView="visible"
          viewport={{
            once: true,
          }}
          variants={{
            hidden: {
              x: "90vw",
              opacity: 0,
            },
            visible: {
              x: 0,
              opacity: [0, 0.5, 1],
              transition: {
                duration: 1,
                delay: 0.5,
                when: "beforeChildren",
              },
            },
          }}
        >
          {skills.map((skill, index) => {
            const { title, amount } = skill;
            return (
              <div key={title} className="relative bg-gray-200 dark:bg-white">
                <motion.div
                  className="relative w-full h-6 md:h-7 bg-primary-dark"
                  variants={skillVariants}
                  custom={{
                    i: index,
                    width: amount,
                  }}
                >
                  <div className="absolute flex items-center justify-center h-full px-2 text-xs font-semibold text-white md:text-sm bg-primary">
                    <span>{title}</span>
                  </div>
                </motion.div>
                <div className="absolute top-0 right-0 flex items-center justify-center h-full pr-1 text-xs font-semibold text-gray-400 md:text-sm dark:bg-white dark:text-gray-700">
                  <Counter from={0} to={amount} delay={index * 0.2} />
                </div>
              </div>
            );
          })}
        </motion.div>
      </div>
    </Container>
  );
}

const skillVariants = {
  hidden: {
    width: 0,
  },
  visible: (custom: { i: number; width: number }) => {
    return {
      width: custom.width + "%",
      transition: {
        delay: custom.i * 0.2,
        duration: 0.8,
      },
    };
  },
};

interface CounterProps {
  from: number;
  to: number;
  delay: number;
}

function Counter({ from, to, delay }: CounterProps) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const controls = animate(from, to, {
      duration: 1,
      delay: delay,
      onUpdate(value) {
        if (ref.current) ref.current.textContent = value.toFixed(0) + "%";
      },
    });
    return () => controls.stop();
  }, [delay, from, to]);

  return <p ref={ref} />;
}

interface AboutItemProps {
  index: number;
  icon: JSX.Element;
  heading: string;
  description: string;
}

const AboutItem = ({ index, icon, heading, description }: AboutItemProps) => {
  const { ref } = useInView({
    threshold: 1,
  });

  return (
    <div ref={ref}>
      <motion.div
        className="flex items-center justify-center w-24 h-24 mx-auto hex sm:h-28 sm:w-28 bg-primary"
        initial="hidden"
        whileInView="visible"
        viewport={{
          once: true,
        }}
        variants={{
          hidden: {
            rotateY: 90,
            opacity: 0,
          },
          visible: {
            rotateY: 0,
            opacity: 1,
            transition: { duration: 0.5, delay: index * 0.3 },
          },
        }}
      >
        {icon}
      </motion.div>
      <div className="text-center">
        <motion.h3
          className="text-lg font-medium text-gray-700 md:text-xl dark:text-gray-50"
          transition={{ duration: 1, delay: index * 0.5 }}
          initial="hidden"
          whileInView="visible"
          viewport={{
            once: true,
          }}
          variants={{
            hidden: {
              opacity: 0,
            },
            visible: {
              opacity: 1,
            },
          }}
        >
          {heading}
        </motion.h3>
        <motion.p
          className="text-xs text-gray-600 md:text-sm dark:text-gray-300"
          transition={{ duration: 1, delay: index * 0.5 }}
          initial="hidden"
          whileInView="visible"
          viewport={{
            once: true,
          }}
          variants={{
            hidden: {
              opacity: 0,
            },
            visible: {
              opacity: 1,
            },
          }}
        >
          {description}
        </motion.p>
      </div>
    </div>
  );
};
